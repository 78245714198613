import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import useTelegram from './useTelegram';
import './FrensPage.css';

const FrensPage = () => {
  const [referralLink, setReferralLink] = useState('');
  const [referralCount, setReferralCount] = useState(0);
  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const tg = useTelegram();

  useEffect(() => {
    if (tg) {
      tg.ready();
      const user = tg.initDataUnsafe.user;
      setUsername(user.username);
    }
  }, [tg]);

  useEffect(() => {
    if (username) {
      setReferralLink(`https://t.me/am_solion_bot?start=${username}`);
      fetchReferralData();
    }
  }, [username]);

  const fetchReferralData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Fetch referral count
      const response = await axios.get(`https://app.solion.meme/api/get_referral_count?username=${username}`);
      setReferralCount(response.data.referral_count);
    } catch (err) {
      console.error("Error fetching referral data:", err);
      setError("Failed to fetch referral data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const copyReferralLink = () => {
    navigator.clipboard.writeText(referralLink);
    alert('Referral link copied to clipboard!');
  };

  const handleShareOnTelegram = () => {
    const shareText = encodeURIComponent(`Check out SOLION! 🦁 I'm earning $SOLION (Listed & Trending MemFi Token). \n\nJoin the fun, let's pump and earn $SOLION together. 🚀 \n\nJoin now at ${referralLink}`);
    window.open(`https://t.me/share/url?url=${shareText}`, '_blank');
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="frens-page">
      <div className="background-wrapper">
        <img className="background-icon2" alt="" src="/background@2x.png" />
      </div>
      <div className="content-wrapper">
        <h1 className="frens-title">Invite Friends</h1>
        <div className="earnings-display">
          <h2>Your Referrals</h2>
          <p>{referralCount} Referrals</p>
        </div>
        <div className="referral-section">
          <h2>Your Referral Link</h2>
          <div className="referral-link-container">
            <input type="text" value={referralLink} readOnly />
            <button onClick={copyReferralLink}>Copy</button>
          </div>
          <button className="share-button" onClick={handleShareOnTelegram}>
            Share on Telegram
          </button>
        </div>
        <div className="referral-info">
          <p>Invite your friends and earn $0.25 worth of SOL for each referral!</p>
          <p>The more you refer, the more you earn!</p>
        </div>
      </div>
      <Navbar />
    </div>
  );
};

export default FrensPage;
