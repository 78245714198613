import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminValidate.css';

const AdminValidate = () => {
  const [pendingSubmissions, setPendingSubmissions] = useState([]);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchPendingSubmissions();
  }, []);

  const fetchPendingSubmissions = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://app.solion.meme/api/get_pending_submissions');
      console.log('Fetched pending submissions:', response.data);
      setPendingSubmissions(response.data.pending_submissions || []);
    } catch (err) {
      console.error('Error fetching pending submissions:', err);
      setError('Failed to fetch pending submissions. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleValidate = async (submissionId, action) => {
    try {
      await axios.post('https://app.solion.meme/api/validate_submission', {
        submission_id: submissionId,
        action,
      });
      setSuccessMessage(`Submission ${action === 'approve' ? 'approved' : 'rejected'} successfully`);
      fetchPendingSubmissions();
    } catch (err) {
      console.error(`Failed to ${action} submission:`, err);
      setError(`Failed to ${action} submission. Please try again.`);
    }
  };

  const SubmissionCard = ({ submission, onValidate }) => (
    <div className="submission-card mb-4">
      <div className="submission-card-header">
        <h3 className="submission-card-title">{submission.task_description}</h3>
        <span className={`submission-card-badge ${submission.is_recurring ? "submission-card-badge-recurring" : "submission-card-badge-normal"}`}>
          {submission.is_recurring ? "Recurring" : "Normal"}
        </span>
      </div>
      <div className="submission-card-content">
        <p><strong>Username:</strong> {submission.username}</p>
        <p><strong>Score:</strong> {submission.task_score}</p>
        <p><strong>Submitted at:</strong> {new Date(submission.submitted_at).toLocaleString()}</p>
        {submission.evidence_url && (
          <div className="evidence-container">
            <h4>Evidence:</h4>
            <img src={submission.evidence_url} alt="Task Evidence" className="evidence-image" />
          </div>
        )}
      </div>
      <div className="submission-card-footer">
        <button 
          onClick={() => onValidate(submission.submission_id, 'approve')} 
          className="button button-primary mr-2"
        >
          Approve
        </button>
        <button 
          onClick={() => onValidate(submission.submission_id, 'reject')} 
          className="button button-danger"
        >
          Reject
        </button>
      </div>
    </div>
  );

  return (
    <div className="admin-validate-container">
      <h1 className="admin-validate-title">Admin Task Validation</h1>
      
      {error && (
        <div className="alert alert-error">
          {error}
        </div>
      )}
      
      {successMessage && (
        <div className="alert alert-success">
          {successMessage}
        </div>
      )}

      <button onClick={fetchPendingSubmissions} className="button button-refresh mb-4">
        Refresh Submissions
      </button>

      {isLoading ? (
        <div className="loading">Loading pending submissions...</div>
      ) : pendingSubmissions.length === 0 ? (
        <p>No pending submissions to validate.</p>
      ) : (
        <div>
          <h2 className="text-xl font-semibold mb-2">Pending Submissions ({pendingSubmissions.length})</h2>
          {pendingSubmissions.map((submission) => (
            <SubmissionCard
              key={submission.submission_id}
              submission={submission}
              onValidate={handleValidate}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminValidate;