import React from 'react';
import Navbar from './Navbar';
import './NftPage.css';

const NftPage = () => {
  return (
    <div className="nft-page">
      <div className="background-wrapper">
        <img className="background-icon2" alt="" src="/background@2x.png" />
      </div>
      <div className="content-wrapper">
        <h1 className="coming-soon">Coming Soon</h1>
        <p className="nft-description">Exciting NFT features are on the way!</p>
      </div>
      <Navbar />
    </div>
  );
};

export default NftPage;