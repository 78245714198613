import React, { useState, useEffect } from 'react';
import axios from "axios";
import useTelegram from "./useTelegram";
import Navbar from './Navbar';
import './WalletPage.css';

const WalletPage = () => {
  const tg = useTelegram();
  const [score, setScore] = useState(0);
  const [solBalance, setSolBalance] = useState(0);
  const [username, setUsername] = useState("");
  const [error, setError] = useState(null);
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [withdrawAddress, setWithdrawAddress] = useState('');
  const [selectedCoin, setSelectedCoin] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (tg) {
      tg.ready();
      const user = tg.initDataUnsafe.user;
      setUsername(user?.username || "");
    }
  }, [tg]);

  useEffect(() => {
    if (username) {
      fetchUserData();
      fetchWithdrawalHistory();
    }
  }, [username]);

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const scoreResponse = await axios.get(`https://app.solion.meme/api/get_user_score?username=${username}`);
      const solResponse = await axios.get(`https://app.solion.meme/api/get_sol_balance?username=${username}`);
      
      setScore(parseFloat(scoreResponse.data.score));
      setSolBalance(parseFloat(solResponse.data.sol_balance));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError("Failed to fetch wallet data. Please try again.");
      setLoading(false);
    }
  };

  const fetchWithdrawalHistory = async () => {
    try {
      const response = await axios.get(`https://app.solion.meme/api/withdrawal_history?username=${username}`);
      setWithdrawalHistory(response.data.history || []);
    } catch (error) {
      console.error('Error fetching withdrawal history:', error);
    }
  };

  const handleWithdraw = (coin) => {
    setSelectedCoin(coin);
    setIsWithdrawModalOpen(true);
  };

  const submitWithdrawal = async () => {
    try {
      const amount = parseFloat(withdrawAmount);
      
      if (isNaN(amount) || amount <= 0) {
        setError("Please enter a valid withdrawal amount.");
        return;
      }

      if (!withdrawAddress.trim()) {
        setError("Please enter a valid withdrawal address.");
        return;
      }

      const response = await axios.post('https://app.solion.meme/api/submit_withdrawal', {
        username,
        amount,
        address: withdrawAddress,
        coin: selectedCoin
      });
      
      if (response.data.success) {
        if (selectedCoin === 'SOLION') {
          setScore(prevScore => parseFloat((prevScore - amount).toFixed(6)));
        } else if (selectedCoin === 'SOL') {
          setSolBalance(prevBalance => parseFloat((prevBalance - amount).toFixed(6)));
        }

        await fetchWithdrawalHistory();

        alert("Withdrawal request submitted successfully");
        setIsWithdrawModalOpen(false);
        setWithdrawAmount('');
        setWithdrawAddress('');
      } else {
        setError(response.data.message || "Failed to submit withdrawal. Please try again.");
      }
    } catch (error) {
      console.error('Error submitting withdrawal:', error);
      setError("Failed to submit withdrawal. Please try again.");
    }
  };

  const formatNumber = (num) => {
    return parseFloat(num).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 6 });
  };

  const coins = [
    { name: 'SOLION', balance: score, logo: '/sollogo.png' },
   
  ];

  if (loading) {
    return <div className="loading">Loading wallet data...</div>;
  }

  return (
    <div className="wallet-page">
      <div className="wallet-header">
        <h1>Lion's Portfolio</h1>
        <p className="username">Welcome, {username}</p>
      </div>
      {error && <div className="error-message">{error}</div>}
      <div className="wallet-content">
        <div className="balance-cards">
          {coins.map((coin) => (
            <div key={coin.name} className="balance-card">
              <div className="card-header">
                <img src={coin.logo} alt={`${coin.name} logo`} className="coin-logo" />
                <h2>SOL</h2>
              </div>
              <p className="balance">{formatNumber(coin.balance)}</p>
              <button className="withdraw-btn" onClick={() => handleWithdraw(coin.name)}>Withdraw</button>
            </div>
          ))}
        </div>
        
        <div className="withdrawal-history-section">
          <h2>Withdrawal History</h2>
          <div className="withdrawal-list">
            {withdrawalHistory.length > 0 ? (
              withdrawalHistory.map((withdrawal, index) => (
                <div key={index} className="withdrawal-item">
                  <div className="withdrawal-info">
                    <p className="withdrawal-date">{new Date(withdrawal.date).toLocaleDateString()}</p>
                    <p className="withdrawal-amount">{formatNumber(withdrawal.amount)} {withdrawal.coin}</p>
                  </div>
                  <span className={`withdrawal-status status-${withdrawal.status}`}>{withdrawal.status}</span>
                </div>
              ))
            ) : (
              <p className="no-history">No withdrawal history available.</p>
            )}
          </div>
        </div>
      </div>
      
      {isWithdrawModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Withdraw {selectedCoin}</h2>
            <p>Enter the amount and address for withdrawal.</p>
            <input
              type="number"
              placeholder="Amount"
              value={withdrawAmount}
              onChange={(e) => setWithdrawAmount(e.target.value)}
              className="modal-input"
              step="0.000001"
            />
            <input
              type="text"
              placeholder="Enter a valid SOL address"
              value={withdrawAddress}
              onChange={(e) => setWithdrawAddress(e.target.value)}
              className="modal-input"
            />
            <div className="modal-actions">
              <button onClick={submitWithdrawal} className="modal-submit-btn">Submit Withdrawal</button>
              <button onClick={() => setIsWithdrawModalOpen(false)} className="modal-cancel-btn">Cancel</button>
            </div>
          </div>
        </div>
      )}

      <Navbar />
    </div>
  );
};

export default WalletPage;