import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useTelegram from "./useTelegram";
import Navbar from "./Navbar";
import "./TasksPage.css";

const TasksPage = () => {
  const navigate = useNavigate();
  const tg = useTelegram();
  const [score, setScore] = useState(0);
  const [username, setUsername] = useState("");
  const [tasks, setTasks] = useState([]);
  const [taskStates, setTaskStates] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [evidence, setEvidence] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [activeTab, setActiveTab] = useState("active");

  useEffect(() => {
    if (tg) {
      tg.ready();
      const user = tg.initDataUnsafe.user;
      setUsername(user.username);
    }
  }, [tg]);

  useEffect(() => {
    if (username) {
      fetchScore();
      fetchTasks();
      fetchTaskStates();
    }
  }, [username]);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    setError(null);

    try {
      await Promise.all([fetchScore(), fetchTasks(), fetchTaskStates()]);
      console.log("Refresh completed successfully");
    } catch (error) {
      console.error("Error during refresh:", error);
      setError("Failed to refresh data. Please try again.");
    } finally {
      setIsRefreshing(false);
    }
  };

  const fetchScore = async () => {
    try {
      const response = await axios.get(
        `https://app.solion.meme/api/get_user_score?username=${username}`
      );
      setScore(response.data.score);
    } catch (error) {
      console.error("Error fetching score:", error);
      throw new Error("Failed to fetch score");
    }
  };

  const fetchTasks = async () => {
    try {
      const response = await axios.get("https://app.solion.meme/api/get_tasks");
      setTasks(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      throw new Error("Failed to fetch tasks");
    }
  };

  const fetchTaskStates = async () => {
    try {
      const response = await axios.get(
        `https://app.solion.meme/api/get_task_states?username=${username}`
      );
      const states = {};
      response.data.taskStates.forEach((state) => {
        states[state.task_id] = state.status;
      });
      setTaskStates(states);
    } catch (error) {
      console.error("Error fetching task states:", error);
      throw new Error("Failed to fetch task states");
    }
  };

  const openTaskLink = (task) => {
    if (task.link && task.link.trim() !== "") {
      window.open(task.link, "_blank", "noopener,noreferrer");
      setTaskStates((prev) => ({ ...prev, [task._id]: "verify" }));
    } else {
      console.error("Invalid task link:", task.link);
      setError("This task doesn't have a valid link.");
    }
  };

  const handleVerifyClick = (task) => {
    setCurrentTask(task);
    setShowModal(true);
  };

  const handleFileChange = (event) => {
    setEvidence(event.target.files[0]);
  };

  const handleSubmitEvidence = async () => {
    if (!evidence) {
      alert("Please select a file to upload.");
      return;
    }

    setIsLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append("image", evidence);

    try {
      const imgurResponse = await axios.post(
        "https://api.imgur.com/3/image",
        formData,
        {
          headers: {
            Authorization: "Client-ID b1b3d4cf9f5e903",
          },
        }
      );

      const evidenceUrl = imgurResponse.data.data.link;

      const response = await axios.post(
        "https://app.solion.meme/api/complete_task",
        {
          username,
          task_id: currentTask._id,
          evidence_url: evidenceUrl,
        }
      );

      console.log("Upload response:", response.data);
      setTaskStates((prev) => ({ ...prev, [currentTask._id]: "validating" }));
      setShowModal(false);
      setEvidence(null);
      setCurrentTask(null);
      alert("Evidence submitted successfully!");
    } catch (error) {
      console.error("Error completing task:", error);
      setError("Failed to submit evidence. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClaimReward = async (taskId, taskScore) => {
    try {
      const response = await axios.post(
        "https://app.solion.meme/api/claim_reward",
        {
          username,
          task_id: taskId,
          score: taskScore,
        }
      );

      if (response.data.new_score) {
        setScore(response.data.new_score);
      } else {
        await fetchScore();
      }

      setTaskStates((prev) => ({ ...prev, [taskId]: "claimed" }));
      fetchTasks();
    } catch (error) {
      console.error("Error claiming reward:", error);
      setError("Failed to claim reward. Please try again.");
    }
  };

  const getTaskStatus = (task) => {
    const status = taskStates[task._id];
    if (status === "validating") return "Validating";
    if (status === "approved") return "Claim";
    if (status === "claimed") return "Claimed";
    if (status === "verify") return "Verify";
    return "Start";
  };

  const handleButtonClick = (task) => {
    const status = getTaskStatus(task);
    if (status === "Start") {
      openTaskLink(task);
    } else if (status === "Verify") {
      handleVerifyClick(task);
    } else if (status === "Claim") {
      handleClaimReward(task._id, task.score);
    }
  };

  const getButtonText = (task) => {
    return getTaskStatus(task);
  };

  const isButtonDisabled = (task) => {
    const status = getTaskStatus(task);
    return status === "Validating" || status === "Claimed";
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const renderTasks = () => {
    // Filter out claimed tasks
    const filteredTasks = tasks.filter((task) => {
      const status = getTaskStatus(task);
      if (status === "claimed") return false; // Do not show claimed tasks
      if (activeTab === "active") {
        return status === "Start" || status === "Verify";
      } else {
        return status === "Validating" || status === "Claim";
      }
    });

    return filteredTasks.map((task) => (
      <div key={task._id} className="task-item">
        <div className="task-content">
          <div className="task-icon">
            <img
              src={task.icon}
              alt=""
              onError={(e) => {
                e.target.src = "/fallback-image.png";
              }}
            />
          </div>
          <div className="task-details">
            <div className="task-description">{task.description}</div>
            <div className="task-reward">+{formatNumber(task.score)} $SOL</div>
            {task.recurInterval && (
              <div className="task-recur-info">
                Recurring every {task.recurInterval} hours
              </div>
            )}
          </div>
        </div>
        <div className="task-action">
          <button
            className={`action-button ${getTaskStatus(task).toLowerCase()}`}
            onClick={() => handleButtonClick(task)}
            disabled={isButtonDisabled(task)}
          >
            {getButtonText(task)}
          </button>
        </div>
      </div>
    ));
  };

  return (
    <div className="tasks-page">
      <div className="background-wrapper">
        <img className="background-icon2" alt="" src="/background@2x.png" />
      </div>
      <Navbar />
      <div className="content-wrapper">
        <div className="fixed-header">
          <div className="game-balance-section">
            <div className="game-balance-frame">
              <div className="frame-parent">
                <div className="frame-group">
                  <div className="ellipse-parent">
                    <img
                      className="frame-child"
                      alt=""
                      src="/ellipse-1@2x.png"
                    />
                    <div className="username">{username}</div>
                  </div>
                  <img
                    className={`refresh-icon1 ${isRefreshing ? "rotating" : ""}`}
                    alt="Refresh"
                    src="/refresh1.svg"
                    onClick={handleRefresh}
                  />
                </div>
                <div className="poold-01-1-group">
                  <img
                    className="poold-01-1-icon2"
                    alt=""
                    src="/sollogo.png"
                  />
                  <div className="div1">{formatNumber(score)}</div>
                </div>
                <b className="game-balance2">SOLION - KING OF MEMES</b>
              </div>
            </div>
          </div>
        </div>
        <div className="tasks-container">
          <div className="tabs">
            <button
              className={`tab ${activeTab === "active" ? "active" : ""}`}
              onClick={() => setActiveTab("active")}
            >
              Active Tasks
            </button>
            <button
              className={`tab ${activeTab === "completed" ? "active" : ""}`}
              onClick={() => setActiveTab("completed")}
            >
              Completed Tasks
            </button>
          </div>
          <div className="scrollable-content">
            <div className="tasks-header">
              <div className="task-title">Are you SO-LION?</div>
              <div className="task-subtitle">
                Show the World how LION you are! Time to Pump $SOLION to the
                Moon. Earn $SOLION by performing the following Tasks.
              </div>
              {error && (
                <div className="error-message">
                  {error}
                  <button
                    onClick={handleRefresh}
                    className="retry-button"
                  >
                    Retry
                  </button>
                </div>
              )}
            </div>
            <div className="task-list">{renderTasks()}</div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Upload Evidence</h2>
            <p>Please upload a screenshot of the action performed.</p>
            <input type="file" accept="image/*" onChange={handleFileChange} />
            {isLoading ? (
              <p>Uploading...</p>
            ) : (
              <>
                <button
                  onClick={handleSubmitEvidence}
                  disabled={!evidence}
                >
                  Submit
                </button>
                <button onClick={() => setShowModal(false)}>Cancel</button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TasksPage;
