import React, { useState, useEffect } from "react";
import axios from "axios";
import { debounce } from 'lodash';
import "./RecurAdmin.css";

const RecurAdmin = () => {
  const [recurTasks, setRecurTasks] = useState([]);
  const [newRecurTask, setNewRecurTask] = useState({ 
    description: "", 
    link: "", 
    score: "", 
    icon: "",
    recurInterval: "",
    status: "active"
  });
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    fetchRecurTasks();
  }, []);

  const fetchRecurTasks = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get("https://app.solion.meme/api/get_recur_tasks");
      setRecurTasks(response.data);
    } catch (error) {
      console.error("Error fetching recurring tasks:", error);
      setError("Failed to fetch recurring tasks. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRecurTask({ ...newRecurTask, [name]: value });
  };

  const debouncedHandleSubmit = debounce(async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      if (editingTaskId) {
        await axios.put(`https://app.solion.meme/api/update_recur_task/${editingTaskId}`, newRecurTask);
        setSuccessMessage("Recurring task updated successfully!");
      } else {
        await axios.post("https://app.solion.meme/api/add_recur_task", newRecurTask);
        setSuccessMessage("New recurring task added successfully!");
      }
      await fetchRecurTasks();
      setNewRecurTask({ description: "", link: "", score: "", icon: "", recurInterval: "", status: "active" });
      setEditingTaskId(null);
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (error) {
      console.error("Error adding/updating recurring task:", error);
      setError("Failed to add/update recurring task. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, 300);

  const handleSubmit = (e) => {
    e.preventDefault();
    debouncedHandleSubmit(e);
  };

  const handleEdit = (task) => {
    setNewRecurTask({
      description: task.description,
      link: task.link,
      score: task.score,
      icon: task.icon,
      recurInterval: task.recurInterval,
      status: task.status
    });
    setEditingTaskId(task._id);
  };

  const handleDelete = async (taskId) => {
    setIsLoading(true);
    setError(null);
    try {
      await axios.delete(`https://app.solion.meme/api/delete_recur_task/${taskId}`);
      await fetchRecurTasks();
      setSuccessMessage("Recurring task deleted successfully!");
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (error) {
      console.error("Error deleting recurring task:", error);
      setError("Failed to delete recurring task. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="recur-admin-container">
      <h2>{editingTaskId ? "Edit Recurring Task" : "Add New Recurring Task"}</h2>
      {error && <div className="error-message">{error}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
      <form onSubmit={handleSubmit} className="recur-task-form">
        <div className="form-group">
          <label htmlFor="description">Task Description:</label>
          <input
            type="text"
            id="description"
            name="description"
            value={newRecurTask.description}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="link">Task Link:</label>
          <input
            type="url"
            id="link"
            name="link"
            value={newRecurTask.link}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="score">Task Score:</label>
          <input
            type="number"
            id="score"
            name="score"
            value={newRecurTask.score}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="icon">Task Icon URL:</label>
          <input
            type="url"
            id="icon"
            name="icon"
            value={newRecurTask.icon}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="recurInterval">Recur Interval (hours):</label>
          <input
            type="number"
            id="recurInterval"
            name="recurInterval"
            value={newRecurTask.recurInterval}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="status">Task Status:</label>
          <select
            id="status"
            name="status"
            value={newRecurTask.status}
            onChange={handleInputChange}
            required
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
        <div className="form-actions">
          <button type="submit" className="btn-primary" disabled={isLoading}>
            {isLoading ? "Processing..." : (editingTaskId ? "Update Recurring Task" : "Add Recurring Task")}
          </button>
          {editingTaskId && (
            <button
              type="button"
              className="btn-secondary"
              onClick={() => {
                setNewRecurTask({ description: "", link: "", score: "", icon: "", recurInterval: "", status: "active" });
                setEditingTaskId(null);
              }}
              disabled={isLoading}
            >
              Cancel Edit
            </button>
          )}
        </div>
      </form>
      <div className="recur-task-list">
        <h3>Existing Recurring Tasks</h3>
        {isLoading ? (
          <p>Loading tasks...</p>
        ) : (
          recurTasks.map((task) => (
            <div key={task._id} className="recur-task-item">
              <img src={task.icon} alt={task.description} className="task-icon" />
              <div className="task-details">
                <h4>{task.description}</h4>
                <p>Link: {task.link}</p>
                <p>Score: {task.score}</p>
                <p>Recur Interval: {task.recurInterval} hours</p>
                <p>Status: {task.status}</p>
              </div>
              <div className="task-actions">
                <button onClick={() => handleEdit(task)} className="btn-secondary" disabled={isLoading}>Edit</button>
                <button onClick={() => handleDelete(task._id)} className="btn-secondary" disabled={isLoading}>Delete</button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default RecurAdmin;