import React, { useState, useEffect } from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from "tsparticles";
import './LoadingPage.css';

const LoadingPage = ({ onLoadingComplete }) => {
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          onLoadingComplete();
          return 100;
        }
        return prevProgress + 1;
      });
    }, 50);

    return () => clearInterval(interval);
  }, [onLoadingComplete]);

  const particlesInit = async (main) => {
    await loadFull(main);
  };

  return (
    <div className="loading-page">
      <div className="background-wrapper">
        <img className="background-icon2" alt="" src="/background@3x.png" />
      </div>
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          particles: {
            number: { value: 50, density: { enable: true, value_area: 800 } },
            color: { value: "#ffffff" },
            shape: { type: "circle" },
            opacity: { value: 0.5, random: true },
            size: { value: 3, random: true },
            move: { enable: true, speed: 1, direction: "none", random: true, out_mode: "out" }
          },
          interactivity: {
            events: {
              onhover: { enable: true, mode: "repulse" },
            },
          },
        }}
      />
      <div className="loading-content">
        
        <h1 className="loading-title">Welcome to Solion</h1>
        <h2 className="loading-subtitle">King of All MEMES</h2>
        <div className="loading-bar-container">
          <div className="loading-bar" style={{ width: `${loadingProgress}%` }}></div>
        </div>
        <p className="loading-percentage">{loadingProgress}%</p>
      </div>
    </div>
  );
};

export default LoadingPage;