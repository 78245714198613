import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <nav className="navbar">
      <Link to="/home" className={`nav-item ${isActive('/home')}`}>
        <svg className="nav-icon" viewBox="0 0 24 24">
          <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
        </svg>
        <span>HOME</span>
      </Link>
      <Link to="/nft" className={`nav-item ${isActive('/nft')}`}>
        <svg className="nav-icon" viewBox="0 0 24 24">
          <path d="M22 9L12 2 2 9h2v11h16V9h2zm-4 9H6v-7h12v7z" />
        </svg>
        <span>NFT</span>
      </Link>
      <Link to="/wallet" className={`nav-item ${isActive('/wallet')}`}>
        <svg className="nav-icon" viewBox="0 0 24 24">
          <path d="M21 18v1c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2V5c0-1.1.89-2 2-2h14c1.1 0 2 .9 2 2v1h-9c-1.11 0-2 .9-2 2v8c0 1.1.89 2 2 2h9zm-9-2h10V8H12v8zm4-2.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
        </svg>
        <span>WALLET</span>
      </Link>
      <Link to="/frens" className={`nav-item ${isActive('/frens')}`}>
        <svg className="nav-icon" viewBox="0 0 24 24">
          <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
        </svg>
        <span>FRENS</span>
      </Link>
    </nav>
  );
};

export default Navbar;