import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import LoadingPage from './LoadingPage';
import TasksPage from "./TasksPage";
import NftPage from "./NftPage";
import WalletPage from "./WalletPage";
import FrensPage from "./FrensPage";
import RecurAdmin from "./RecurAdmin";
import TaskAdmin from "./TaskAdmin";
import ValidateTask from "./ValidateTask";
import AdminWithdrawPage from './AdminWithdrawPage';
import AdminValidate from './AdminValidate';
import AdminDashboard from './AdminDashboard';

const manifestUrl = "https://app.solion.meme/tonconnect-manifest.json";

// Wrapper component for routes that need the loading screen
const LoadingWrapper = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingPage onLoadingComplete={() => setIsLoading(false)} />;
  }

  return children;
};

function App() {
  return (
    <TonConnectUIProvider manifestUrl={manifestUrl}>
      <Router>
        <Routes>
          <Route path="/" element={<LoadingWrapper><TasksPage /></LoadingWrapper>} />
          <Route path="/home" element={<LoadingWrapper><TasksPage /></LoadingWrapper>} />
          <Route path="/nft" element={<LoadingWrapper><NftPage /></LoadingWrapper>} />
          <Route path="/wallet" element={<LoadingWrapper><WalletPage /></LoadingWrapper>} />
          <Route path="/frens" element={<LoadingWrapper><FrensPage /></LoadingWrapper>} />
          <Route path="/recur-task" element={<RecurAdmin />} />
          <Route path="/task-admin" element={<TaskAdmin />} />
          <Route path="/validate-task" element={<ValidateTask />} />
          <Route path="/admin/withdrawals" element={<AdminWithdrawPage />} />
          <Route path="/admin/validate" element={<AdminValidate />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
        </Routes>
      </Router>
    </TonConnectUIProvider>
  );
}

export default App;